<template>
  <b-card
    title="รายชื่อสมาชิก"
    no-body
  >
    <b-card-body>
      <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="เบอร์โทร / ยูส"
              label-for="mc-first-name"
            >
              <b-form-input
                id="mc-first-name"
                v-model="tableQuery.phoneno"
                placeholder="เบอร์โทร / ยูส"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="ชื่อ-สกุล"
              label-for="mc-last-name"
            >
              <b-form-input
                id="mc-last-name"
                v-model="tableQuery.fullname"
                placeholder="ชื่อ-สกุล"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="ธนาคาร"
              label-for="mc-city"
            >
              <b-row>
                <b-col md="6">
                  <v-select
                    v-model="tableQuery.bank_code"
                    label="text"
                    :options="bankListOptions"
                  >
                    <template #option="{ text, value }">
                      <img :src="getBankImage(value)" style="width: 24px;">
                      <span> {{ text }}</span>
                    </template>
                  </v-select>
                </b-col>
                <b-col md="6">
                  <cleave
                    id="topup-amopunt"
                    v-model="tableQuery.bank_acc_no"
                    class="form-control"
                    :raw="false"
                    :options="{ numeral: true, numeralThousandsGroupStyle: 'none' }"
                    placeholder="0000000000"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="วันที่สมัคร"
              label-for="mc-country"
            >
              <b-row>
                <b-col md="6">
                  <flat-pickr
                    v-model="tableQuery.startdate"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                  />
                </b-col>
                <b-col md="6">
                  <flat-pickr
                    v-model="tableQuery.enddate"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', time_24hr: true, minuteIncrement:1 }"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <!-- submit and reset -->
          <b-col md="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="searchMember"
            >
              ค้นหา
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="clearSearch"
            >
              เคลียร์
            </b-button>
          </b-col>
          <b-col md="6" style="text-align: right;">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              class="mr-1"
              @click="createMember"
            >
              เพิ่มสมาชิกใหม่
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!-- </div> -->
    </b-card-body>

    <b-table
      ref="membertable"
      striped
      hover
      responsive
      class="position-relative"
      :busy.sync="isBusy"
      :per-page="tableQuery.page_size"
      :current-page="tableQuery.page"
      :items="fetchMember"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(username)="data">
        <span v-if="data.item.phoneno.indexOf('X') < 0">{{ data.item.phoneno }}<br></span>
        {{ data.item.username }}
        <b-badge v-if="data.item.level != 'MEMBER'" :variant="'light-danger'">
          {{ data.item.level }}
        </b-badge>
        <b-badge v-if="data.item.group_id != 1 && member_groups.filter(i => i.id == data.item.group_id)[0].tag" :variant="'light-danger'">
          {{ member_groups.filter(i => i.id == data.item.group_id)[0].tag }}
        </b-badge>
      </template>
      <template #cell(fullname)="data">
        {{ data.item.fname }} {{ data.item.lname }}
      </template>
      <template #cell(bank_acc_no)="data">
        <img :src="getBankImage(data.item.bank_code)" style="width: 24px; margin-bottom:5px"> {{ data.item.bank_name }}<br>{{ data.item.bank_acc_no }}
      </template>
      <template #cell(credit)="data">
        <div
          v-if="data.item.wallet"
          style="text-align: right;">
          {{ `${data.item.wallet.credit}` }}
        </div>
      </template>
      <template #cell(get_promo)="data">
        <b-badge :variant="{'Y':'light-success', 'N':'light-primary'}[data.item.get_promo]">
          {{ {'Y':'รับโปร', 'N':'ไม่รับ'}[data.item.get_promo] }}
        </b-badge>
      </template>
      <template #cell(invited_by)="data">
        <b-badge v-if="!data.item.invited_by" :variant="'light-danger'">
          ไม่มี
        </b-badge>
        <div v-if="data.item.invited_by">
          <span v-if="data.item.phoneno.indexOf('X') < 0">{{ data.item.refer_by.phoneno }}<br></span>
          {{ data.item.refer_by.fname }} {{ data.item.refer_by.lname }}
        </div>
      </template>
      <template #cell(status)="data">
        <div style="text-align:center;">
          <b-badge :variant="{'Y':'light-success', 'N':'light-danger'}[data.item.is_active]">
            {{ {'Y':'แอคทีฟ', 'N':'ระงับ'}[data.item.is_active] }}
          </b-badge><br>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :variant="{'Y':'outline-danger', 'N':'outline-success'}[data.item.is_active]"
            size="sm"
            @click="updateMemberStatus(data.item, data.item.is_active === 'Y' ? 'inactive' : 'active')"
          >
            {{ {'Y':'ระงับยูสนี้', 'N':'แอคทีฟยูสนี้'}[data.item.is_active] }}
          </b-button>
        </div>
      </template>
      <template #cell(afflink)="data">
        <div style="text-align:center;">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-info"
            size="sm"
            @click="copyReferralLink(data.item)"
          >
            <feather-icon
              icon="CopyIcon"
              class="mr-50"
            />
            ก๊อปลิ้งชวนเพื่อน
          </b-button>
        </div>
      </template>
      <template #cell(btn_credit)="data">
        <div style="text-align:center;">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-info"
            size="sm"
            @click="updateCredit(data.item)"
          >
            <feather-icon
              icon="RefreshCcwIcon"
              class="mr-50"
            />
            เครดิต
          </b-button>
        </div>
      </template>
      <template #cell(btn_detail)="data">
        <div style="text-align:center;">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-info"
            size="sm"
            @click="viewMember(data.item)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            แก้ไขข้อมูล
          </b-button>
        </div>
      </template>

    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="tableQuery.page_size"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="tableQuery.page"
          :total-rows="totalRows"
          :per-page="tableQuery.page_size"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody, BRow, BCol, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    Cleave,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bankListOptions: JSON.parse(localStorage.getItem('bank-lists') || '[]'),
      isBusy: false,
      pageOptions: [100, 200, 500, 1000],
      totalRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 100,
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'username', label: 'ยูสเซอร์' },
        { key: 'fullname', label: 'ชื่อลูกค้า' },
        { key: 'bank_acc_no', label: 'เลขที่บัญชี' },
        { key: 'credit', label: 'ยอดเครดิต' },
        { key: 'get_promo', label: 'รับโปร' },
        { key: 'invited_by', label: 'คนชวน' },
        { key: 'created_at', label: 'สมัครเมื่อ', formatter: val => this.formatDate(val) },
        { key: 'updated_at', label: 'เข้าเล่นล่าสุด', formatter: val => this.formatDate(val) },
        { key: 'status', label: 'สถานะ' },
        { key: 'afflink', label: 'ลิ้งชวนเพื่อน' },
        { key: 'btn_detail', label: 'แก้ไขข้อมูล' },
      ],
      /* eslint-disable global-require */
      items: [
      ],
      member_groups: JSON.parse(localStorage.getItem('mg') || '[]'),
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    // getBankOptions() {
    //   return this.bankListOptions
    // },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.fetchMemberGroups()
    // this.fetchMember()
  },
  methods: {
    createMember() {
      this.$router.push('/members/create')
    },
    searchMember() {
      this.$refs.membertable.refresh()
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
      }
      this.$refs.membertable.refresh()
    },
    updateMemberStatus(data, status) {
      this.$swal({
        title: status === 'inactive' ? 'ยืนยันการระงับสมาขิก?' : 'ยืนยันการปลดล๊อคสมาชิก?',
        text: `คุณกำลังจะ${status === 'inactive' ? 'ระงับ' : 'ปลดล๊อค/แอคทีฟ'}การใช้งานสมาชิก ${data.phoneno} ${data.fname} ${data.lname} ใช่ไหม`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ใช่ ตกลง',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value)
          return this.$http.post(`/users/update-active/${data.id}`, { is_active: status === 'inactive' ? 'N' : 'Y' })
        return { data: { success: false } }
      }).then(({ data: updateResult }) => {
        if (updateResult.success) {
          this.$swal({
            icon: 'success',
            title: `${(status === 'inactive' ? 'ระงับสมาขิก' : 'ปลดล๊อคสมาชิก')}เรียบร้อย`,
            text: 'ปรับสถานะของสมาชิกเรียบร้อย',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$refs.membertable.refresh()
        }
      }).catch(err => {
        this.$bvToast.toast(`เกิดข้อผิดพลาด | ${err}`, {
          variant: 'danger',
          title: 'อัพเดดไม่สำเร็จ',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
        })
      })
    },
    async fetchMember(ctx) {
      console.log('[fetchMember]', ctx)
      let query = ''
      if (this.tableQuery !== {}) {
        Object.keys(this.tableQuery).forEach((key, idx) => {
          if (idx > 0) query += '&'
          if (this.tableQuery[key].value)
            query += `${key}=${encodeURIComponent(this.tableQuery[key].value)}`
          else
            query += `${key}=${encodeURIComponent(this.tableQuery[key])}`
        })
      }

      const { data: { success, users } } = await this.$http.get(`/users${query !== '' ? (`?${query}`) : ''}`)
      if (success) {
        this.items = users.data
        this.totalRows = users.meta.total
      }
      return this.items
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.tableQuery.page = 1
    },
    copyReferralLink(data) {
      // console.log('copyReferralLink', data)
      this.copyTextToClipboard(`https://wallet.${location.host.split('.').slice(-2).join('.')}/register/${data.affiliate_code}`)
      this.$bvToast.toast(`ลิ้งชวนเพื่อนของ ${data.phoneno} : ${data.fname} ${data.lname} คือ https://wallet.${location.host.split('.').slice(-2).join('.')}/register/${data.affiliate_code}`, {
        variant: 'success',
        title: `คัดลอกลิ้งชวนเพื่อนของ ${data.phoneno} : ${data.fname} ${data.lname} สำเร็จ`,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        appendToast: false,
      })
    },
    copyTextToClipboard(text) {
      const textArea = document.createElement('textarea')

      //
      // *** This styling is an extra step which is likely not required. ***
      //
      // Why is it here? To ensure:
      // 1. the element is able to have focus and selection.
      // 2. if element was to flash render it has minimal visual impact.
      // 3. less flakyness with selection and copying which **might** occur if
      //    the textarea element is not visible.
      //
      // The likelihood is the element won't even render, not even a
      // flash, so some of these are just precautions. However in
      // Internet Explorer the element is visible whilst the popup
      // box asking the user for permission for the web page to
      // copy to the clipboard.
      //

      // Place in top-left corner of screen regardless of scroll position.
      textArea.style.position = 'fixed'
      textArea.style.top = 0
      textArea.style.left = 0

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em'
      textArea.style.height = '2em'

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = 0

      // Clean up any borders.
      textArea.style.border = 'none'
      textArea.style.outline = 'none'
      textArea.style.boxShadow = 'none'

      // Avoid flash of white box if rendered for any reason.
      textArea.style.background = 'transparent'
      textArea.value = text

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        document.execCommand('copy')
      } catch (err) {
        console.log(1)
      }

      document.body.removeChild(textArea)
    },
    viewMember(data) {
      this.$router.push({ name: 'member-detail', params: { user: data.username } })
    },
    async fetchMemberGroups() {
      const { data: { member_groups: memberGroups } } = await this.$http.get('users/groups')
      localStorage.setItem('mg', JSON.stringify(memberGroups))
      this.member_groups = memberGroups
      // eslint-disable-next-line camelcase
      // this.items = member_groups
      // this.totalRows = member_groups.length
      // this.promotionEventMaps = promotionEvents
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
